<template>
  <component
    :is="generalView ? 'b-card' : 'div'"
    :body-class="generalView ? 'pb-0' : ''"
    :class="generalView ? 'map-container' : isEditable ? 'editable' : inContainer ? 'completedMap' : 'map'"
  >
    <span v-if="generalView" class="d-flex flex-wrap mt-50">
      <b-button variant="link" @click="isLegendModalVisible = true" class="p-0 mb-1">
        <feather-icon
          icon="MapPinIcon"
          size="18"
          class="text-primary share-icon mr-25"
          role="button"
        />
        <span class="text-primary">
          {{ $t('maps.see-legend') }}
        </span>
      </b-button>

      <!-- Legend modal -->
      <b-modal
        :title="$t('maps.legend')"
        v-model="isLegendModalVisible"
        size="sm"
        :ok-title="$t('form.actions.ok')"
        ok-only
      >
        <span v-for="(ico, index) in icons" :key="index" class="mr-50 mt-25 d-flex">
        <p class="font-weight-bold mb-0 d-flex">
          <img :src="ico.image" class="b-avatar mb-1 badge-light-secondary rounded-circle p-25 mr-25" style="height: 25px; width: 25px"/>
          {{ ico.name }} 
        </p>
      </span>
      </b-modal>
    </span>
    <div ref="map" :class="generalView ? 'generalMap' : 'position'"/>
  </component>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { getImageResource } from "@/@core/utils/image-utils";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { randomString } from '@/@core/utils/utils';
import { LocationsPinImagesForApps, LocationPinImages, AppsWithPins } from '@/@core/constants/LocationPins';
export default {
  name: "PlacesMap",
  
  props: {
    locations: {
      type: Array, // Location[]
      required: true,
      default: []
    },
    generalView: {
      type: Boolean,
    },
    inContainer: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      google: null,
      map: null,
      keyOfMap: randomString(10),

      markers: [],
      bounds: [],

      activeInfoWindow: null,
      isLegendModalVisible: false,
      defaultPinImage: LocationPinImages.redDot,
      homePinIcon: LocationsPinImagesForApps.communities,
      organizationPinImage: LocationsPinImagesForApps.organizations,
      societyPinImage: LocationsPinImagesForApps.societies,
      memberPinImage: LocationsPinImagesForApps.members,              
      contactPinImage: LocationsPinImagesForApps.contacts,
      challengePinImage: LocationsPinImagesForApps.challenges,
      eventPinImage: LocationsPinImagesForApps.events,              
      roomPinImage: LocationsPinImagesForApps.rooms,
      servicePinImage: LocationsPinImagesForApps.services,
      productPinImage: LocationsPinImagesForApps.products,
      icons: [
        { 
          image: LocationsPinImagesForApps.community,
          name: this.$t('backoffice.discounts.model-types.community') 
        },
      ],
      defaultCenter: { //TODO: get from Map addon settings
        lat: 41.391519515630414, 
        lng: 2.1808292911030125 
      },
      defaultZoom: 13 //TODO: get from Map addon settings
    };
  },
  computed: {
    enabledApps() {
      // var installedAppsArray = JSON.parse(JSON.stringify(this.$store.getters.installedApps))?.data;
      // return installedAppsArray?.map( 
      //   (item) => item.addonSingular
      // );

      return this.$store.getters.installedApps.data?.map( //this comes from this.getInstalledApps()
        (item) => item.addonSingular
      );
    },
    enabledOrganizationApp() {
      return this.enabledApps?.filter(item=> item === 'organization').length > 0;
    },
    enabledMembersApp() {
      return this.enabledApps?.filter(item=> item === 'member').length > 0;
    },
    enabledEventsApp() {
      return this.enabledApps?.filter(item=> item === 'event').length > 0;
    },
    enabledSocietiesApp() {
      return this.enabledApps?.filter(item=> item === 'society').length > 0;
    },
    enabledRoomsApp(){
      return this.enabledApps?.filter(item => item === 'room').length > 0;
    },
    enabledServicesApp(){
      return this.enabledApps?.filter(item => item === 'service').length > 0;
    },
    enabledContactsApp(){
      return this.enabledApps?.filter(item => item === 'contact').length > 0;
    },
    enabledChallengesApp(){
      return this.enabledApps?.filter(item => item === 'challenge').length > 0;
    }
  },
  watch: {
    locations(locations) {
      if (!this.google) return;

      this.addLocations(locations);
    },
  },
  async mounted() {
    //console.log('Mounting Places Map...');
    
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });
    this.google = await loader.load();

    // console.log('PlacesMap mounted this.defaultCenter', this.defaultCenter);
    this.map = new this.google.maps.Map(this.$refs.map, {
      center: this.defaultCenter,
      zoom: this.defaultZoom,
      controlSize: 25,
      mapTypeId: "roadmap",
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    });

    this.addLocations(this.locations);

    //1) Se cogen las apps que están instaladas
    let installedApps = this.enabledApps;
    if(!installedApps || installedApps === undefined){
      // console.log('Apps not found...'); //arreglar esto
      installedApps = await this.getAppsNamesList();
    }
    // console.log('installedApps', installedApps);
    // console.log('AppsWithPins', AppsWithPins);
    for(let app of AppsWithPins){
      // console.log('app with pins:', app);
      if (installedApps?.filter(item => item === app).length > 0) {
        // console.log('Installed app has locations:', app);
        // console.log(app+'.title');
        this.icons.push({ 
          image: LocationsPinImagesForApps[app], 
          name: this.$t(app+'.title') 
        })
      }
    }
    // console.log('this.icons', this.icons);
    // if (this.enabledOrganizationApp) {
    //   this.icons.push({ 
    //     image: this.organizationPinImage, 
    //     name: this.$t('organizations.title') 
    //   })
    // }
    // if (this.enabledEventsApp) {
    //   this.icons.push({ 
    //     image: this.eventPinImage, 
    //     name: this.$t('events.title') 
    //   })
    // }
    // if (this.enabledMembersApp) {
    //   this.icons.push({ 
    //     image: this.memberPinImage, 
    //     name: this.$t('members.title') 
    //   })
    // }
    // if (this.enabledSocietiesApp) {
    //   this.icons.push({ 
    //     image: this.societyPinImage,
    //     name: this.$t('societies.title') 
    //   })
    // }
    // if(this.enabledRoomsApp) {
    //   this.icons.push({
    //     image: this.roomPinImage,
    //     name: this.$t("rooms.title"),
    //   })
    // }
    // if(this.enabledServicesApp) {
    //   this.icons.push({
    //     image: this.servicePinImage,
    //     name: this.$t("services.title"),
    //   })
    // }
    // if(this.enabledContactsApp) {
    //   this.icons.push({
    //     image: this.contactPinImage,
    //     name: this.$t("contacts.title"),
    //   })
    // }
    // if(this.enabledChallengesApp) {
    //   this.icons.push({
    //     image: this.challengePinImage,
    //     name: this.$t("challenges.title"),
    //   })
    // }
  },
  methods: {
    async getAppsNamesList() {
      //console.log('this.$store.getters.apps.length', this.$store.getters.apps.length);
      // console.log('this.$store.getters.apps.apps', this.$store.getters.apps.apps);

      var appsList = [];
      let installedAppsArray = [];

      if(this.$store.getters.apps.length){
        var installedApps = JSON.parse(JSON.stringify(this.$store.getters.apps.apps));

        // console.log('installedApps', installedApps);
        // console.log('typeof installedApps', typeof installedApps);

        installedAppsArray = Object.values(installedApps);
        // console.log('installedAppsArray', installedAppsArray);


        for(let app of installedAppsArray){
          // console.log('app', app);
          appsList.push(app.key);
        }
      }
      // console.log('appsList', appsList);
      return appsList;

      return installedAppsArray?.map( 
        (item) => item.addonSingular
      );
    },
    async getInstalledApps() {
      await this.$store.dispatch("getInstalledApps");
    },
    async fetchRoomEvents(locations) {
      for(let place of locations) {
        if(place.modelType === "room") {
        const requestConfig = {
          communityParentSlug: this.$store.getters.currentCollective.slug,
          orderByDate: 1,
          isEvent: true,
          roomKey: place.part.key,
        };

        await this.$store.dispatch("getItems", {
          itemType: "communities/simply",
          customName: "communitiesOthers",
          storedKey: `${place.part.key}-events`,
          page: 1,
          perPage: this.perPage,
          forceAPICall: true,
          requestConfig,
        });
        }
      }

      setTimeout(() => {
        this.locations = [...locations];
      }, 5000)
    },
    async addLocations(locations) {
      if (!locations.length) {
        return;
      }

      // Clear out the old marker
      if (this.markers) {
        this.markers.forEach((marker) => marker.setMap(null));
        this.markers = [];
      }

      locations.forEach( async (location) => {
        if(location.latitude && location.longitude){
          await this.addPlace({
            name: location.name,
            location,
            part: location.part,
            modelType: location.modelType,
            geometry: {
              location: {
                lat: parseFloat(location.latitude),
                lng: parseFloat(location.longitude),
              },
            },
          });
        }
      });

      if (this.markers.length >= 1) {
        const bounds = new this.google.maps.LatLngBounds();
        var lastMarkerPosition = null;
        this.markers.forEach((marker) => {
          lastMarkerPosition = marker.getPosition();
          bounds.extend(lastMarkerPosition);
        });

        if(this.markers.length <= 1){
          this.map.setCenter(lastMarkerPosition);
          this.map.setZoom(10);
        }else {
          this.map.fitBounds(bounds);
        }
      }
      /*else {
        // Solo 1 marcador
        console.log('addLocations this.markers[0]:', this.markers[0]);
        let centerPosition = this.markers[0].getPosition();
        console.log('addLocations centerPosition 1:', centerPosition);
        if(!centerPosition.lat || !centerPosition.lng || (!this.isNumber(centerPosition.lat) && !this.isNumber(centerPosition.lng))){
          centerPosition = this.defaultCenter;
        }
        console.log('addLocations centerPosition 2:', centerPosition);
        this.map.setCenter(centerPosition);
        this.map.setZoom(this.defaultZoom);
      }*/
    },
    isNumber(value) {
      return typeof value === 'number' && isFinite(value);
    },
    async addPlace(place) {
      const marker = new this.google.maps.Marker({
        map: this.map,
        title: place.name,
        position: place.geometry.location,
        icon: {
          url: this.getPinImageUrlFromModelType(place.modelType),
          scaledSize: new google.maps.Size(35, 35),
        },
      });
      let events = "";
      //If it is a room, take the events happening in that room:
      if (place.modelType === "room") {
        if(this.$store.getters.communitiesOthers?.[
          `${place.part.key}-events`
        ]?.unpaginated){
        for (let event of this.$store.getters.communitiesOthers?.[
          `${place.part.key}-events`
        ].unpaginated) {
          events += `
        <div class="mt-2">
          <p class="font-weight-bold">${translateTranslationTable(
                      this.$store.getters.currentLocale,
                      event.name
                    )}</p>

            ${ event.logoURL ? 
            `<img src="${event.logoURL}" style="width: 100%; height: 50px"/>`
            : ''
        }

          <p>${event.startDate} - ${event.endDate}</p>

        </div>
        `;
        }
        }
      }
      const { label, latitude, longitude, part, address, name } = place.location;
      
      // Info window to appear when clicking on the pin:
      const infowindow = new this.google.maps.InfoWindow({
        content: `
          <div class="row mx-0">
            ${
              part?.logoURL
                ? `
              <div class="col-2 p-0 mr-1 d-flex align-items-center">
                <img class="d-block w-100" src="${ getImageResource(part.logoURL) }"/>
              </div>
            `
                : ""
            }
            <div class="col p-0">
              <h5>${ this.pinLabel(label, name, address, part) }</h5>
              <p>${ this.getModelTypeLabel(place.modelType) }</p>
              <a
                href="https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}"
                target="_blank"
              >
                ${
                  address ||
                  place.location.location ||
                  label ||
                  this.getName(part?.name)
                }
              </a>
              ${
                place.modelType === "room" ? `
                <div style="overflow-y: scroll; max-height: 100px">
                ${events}
                </div>`
                  : ""
              }
            </div>
          </div>
        `,
      });
      marker.addListener("click", () => {
        if (this.activeInfoWindow) {
          this.activeInfoWindow.close();
          this.activeInfoWindow = null;
        }
        infowindow.open(marker.get("map"), marker);
        this.activeInfoWindow = infowindow;
      });
      this.markers.push(marker);
    },
    getMap() {
      return this.map;
    },
    pinLabel(label, name, address, part) {
      //console.log(label, name, address, part, this.$store.getters.currentCollective.name);
      return label && label != address
                        ? label
                        : this.getName(part?.name) || name || this.$store.getters.currentCollective.name;
    },
    getName(name) {
      if (typeof name == "object") {
        return (
          name[this.$store.state.locale.currentLocale] || Object.values(name)[0]
        );
      }
      return name;
    },
    getModelTypeLabel(modelType) {
      return `<span style="background-color: lightgrey; color: #5f5f5f; font-weight: 700; padding: 1px 10px; border-radius: 8px; ">`+modelType+`</span>`;
    },
    getPinImageUrlFromModelType(modelType) {
      if (modelType === "event") {
        return this.eventPinImage;
      }
      if (modelType === "organization") {
        return this.organizationPinImage;
      }
      if (modelType === "member") {
        return this.memberPinImage;
      }
      if (modelType === "society") {
        return this.societyPinImage;
      }
      if (modelType === "room") {
        return this.roomPinImage;
      }
      if (modelType === "service") {
        return this.servicePinImage;
      }
      if (modelType === "contact") {
        return this.contactPinImage;
      }
      if (modelType === "challenge") {
        return this.challengePinImage;
      }
      return this.homePinIcon;
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  height: 80%;
  overflow: auto;
}
.editable {
  height: 90%;
}
.completedMap {
  height: 100%;
}
.position {
  height: 100%;
  width: 100%;
  border-radius: 1.2rem;
}
.map-container {
  width: 100%;
  margin: auto;
}
.generalMap {
  height: 650px;
  width: 100%;
  margin-bottom: 25px;
  border-radius: 1.2rem;
}
</style>

<style>
.pac-container {
  z-index: 1051 !important;
}
</style>
